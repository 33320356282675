import React, { useRef, useState } from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
// import ReactResizeDetector from 'react-resize-detector'
import { useResizeDetector } from 'react-resize-detector'
import debounce from 'lodash/debounce'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import { BiSupport as BiSupportIcon } from 'react-icons/bi'
import { IoMdMoon as IoMdMoonIcon } from 'react-icons/io'
import CloseIcon from '@material-ui/icons/Close'

import PopupContent from 'components/organisms/PopupContent'
import withCompWidth from 'commonHoc/withCompWidth'

import { isNumber } from 'lib/detectType'
import { makeStyleObject as ms, getClassName as cn } from 'lib/getBreakPoints'

const styles = theme => ({
  popup: {
    width: 500,
    maxWidth: '100%',
    maxHeight: '90%',
    margin: theme.spacing(1)
  },
  ...ms('popup', 'md', {
    maxHeight: '95%',
    margin: theme.spacing(2)
  }),
  popupTitleText: {
    fontSize: '0.9em',
    wordBreak: 'break-word'
  },
  ...ms('popupTitleText', 'md', {
    fontSize: '1em'
  }),
  dialogTitleRoot: {
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    transition: 'box-shadow .2s, padding .2s',
    boxShadow: '0 0 15px #00000012'
  },
  ...ms('dialogTitleRoot', 'md', {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }),
  dialogTitleTop: {
    boxShadow: 'none'
  },
  dialogTitleMid: {},
  dialogTitleBot: {},
  dialogRootLowZindex: {
    zIndex: '1000 !important'
  },
  dialogContent: {
    padding: '0',
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#777'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#ccc'
    },
    '&&': {
      [theme.breakpoints.down('sm')]: {}
    }
  },
  dialogActionsRoot: {
    boxShadow: '0 0 10px #00000012',
    transition: 'box-shadow .2s, padding .2s'
  },
  dialogActionsTop: {},
  dialogActionsMid: {},
  dialogActionsBot: {
    boxShadow: 'none'
  },
  popupText: {
    padding: '0 1em 1em 1em'
  },
  padding: {
    padding: theme.spacing(2)
  },
  titlePadding: {
    padding: theme.spacing(2),
    paddingTop: 4
  },
  supportButton: {
    display: 'inline-block',
    width: 8,
    height: 8,
    marginLeft: theme.spacing(1),
    borderRadius: '50%'
  },
  supportLive: {
    animation: `$supportLive 3s cubic-bezier(.36,.07,.19,.97) infinite`,
    backgroundColor: '#f00'
  },
  contentWrap: {
    height: '100%'
  },
  '@keyframes supportLive': {
    '1%': {
      boxShadow: '0 0 0 0 #f00'
    },
    '50%': {
      boxShadow: '0 0 10px 0 #f00'
    },
    '90%': {
      boxShadow: '0 0 20px 0 #f00'
    },
    '100%': {
      boxShadow: '0 0 40px 0 rgba(255, 0, 0, 0.5)'
    }
  }
})

const scrollListener = debounce(
  (scroller, e, startCallback, middleCallback, endCallback) => {
    if (!scroller) return
    const isCurrentScrTop = scroller.scrollTop === 0
    const isCurrentScrBottom =
      scroller.scrollHeight > scroller.offsetHeight &&
      scroller.scrollHeight - scroller.offsetHeight - scroller.scrollTop < 5
    if (isCurrentScrTop) {
      startCallback()
    }
    if (!isCurrentScrTop && !isCurrentScrBottom) {
      middleCallback()
    }
    if (isCurrentScrBottom) {
      endCallback()
    }
  },
  500,
  { leading: true, trailing: true }
)
const ComponentPopup = props => {
  const { classes, ...others } = props
  const {
    proxy,
    width,
    isWidthDown,
    popupKey,
    openFlag,
    children,
    title,
    padding,
    disableActions,
    supportButton,
    closeButton,
    message: Context,
    actionComponent,
    actions = [],
    lowZindex = false,
    isSupportLive,
    dialogProps = {},
    childrenProps = {}
  } = others
  const { onClose, onClickSupport } = proxy
  const { disableEscapeKeyDown } = dialogProps
  const popupContentRef = useRef()
  const [currentScrollPosition, setScrollPosition] = useState('TOP')
  const isMobile = isWidthDown('xs', width)
  if (popupContentRef?.current) {
    popupContentRef.current.addEventListener('scroll', event => {
      scrollListener(
        popupContentRef.current,
        event,
        () => {
          if (currentScrollPosition !== 'TOP') {
            setScrollPosition('TOP')
          }
        },
        () => {
          if (currentScrollPosition !== 'MID') {
            setScrollPosition('MID')
          }
        },
        () => {
          if (currentScrollPosition !== 'BOT') {
            setScrollPosition('BOT')
          }
        }
      )
    })
  }
  const {
    width: popupContentWidth,
    height: popupContentHeight,
    ref
  } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 500
  })
  return (
    <div>
      <Dialog
        classes={{
          root: classNames({
            [classes.dialogRootLowZindex]: lowZindex
          }),
          paper: classes.popup
        }}
        open={Boolean(openFlag)}
        onClose={onClose}
        disableEnforceFocus
        {...dialogProps}
      >
        {title && (
          <DialogTitle
            classes={{
              root: classNames(cn(classes, 'dialogTitleRoot', width), {
                [classNames(cn(classes, 'dialogTitleTop', width))]:
                  currentScrollPosition === 'TOP',
                [classNames(cn(classes, 'dialogTitleMid', width))]:
                  currentScrollPosition === 'MID',
                [classNames(cn(classes, 'dialogTitleBot', width))]:
                  currentScrollPosition === 'BOT'
              })
            }}
          >
            <Grid
              container
              wrap="nowrap"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs zeroMinWidth>
                <Typography className={cn(classes, 'popupTitleText', width)}>
                  {title}
                </Typography>
              </Grid>
              {closeButton && (
                <Grid item xs="auto">
                  <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </DialogTitle>
        )}
        <DialogContent
          ref={popupContentRef}
          classes={{
            root: classNames(classes.dialogContent, {
              [cn(classes, title ? 'titlePadding' : 'padding', width)]: padding
            })
          }}
        >
          <div className={classes.contentWrap} ref={ref}>
            <PopupContent
              Context={Context}
              popupKey={popupKey}
              childrenProps={childrenProps}
              popupContentWidth={
                isNumber(popupContentWidth)
                  ? popupContentWidth - 16
                  : popupContentWidth
              }
              popupContentHeight={
                isNumber(popupContentHeight)
                  ? popupContentHeight - 8
                  : popupContentHeight
              }
            >
              {children}
            </PopupContent>
          </div>
        </DialogContent>
        {disableActions !== true && (
          <DialogActions
            classes={{
              root: classNames(cn(classes, 'dialogActionsRoot', width), {
                [classNames(cn(classes, 'dialogActionsTop', width))]:
                  currentScrollPosition === 'TOP',
                [classNames(cn(classes, 'dialogActionsMid', width))]:
                  currentScrollPosition === 'MID',
                [classNames(cn(classes, 'dialogActionsBot', width))]:
                  currentScrollPosition === 'BOT'
              })
            }}
          >
            {actionComponent && <>{actionComponent}</>}
            {actions.length > 0 &&
              actions.map((action, index) => {
                return (
                  <Button
                    key={index}
                    size={isMobile ? 'small' : 'midium'}
                    {...action}
                  >
                    {action.title}
                  </Button>
                )
              })}
            {supportButton && (
              <Button
                onClick={onClickSupport}
                size={isMobile ? 'small' : 'midium'}
              >
                <Box mr={1}>
                  {isSupportLive() ? <BiSupportIcon /> : <IoMdMoonIcon />}
                </Box>
                채팅 문의
                {isSupportLive() && (
                  <div
                    className={classNames({
                      [classes.supportButton]: true,
                      [classes.supportLive]: isSupportLive()
                    })}
                  />
                )}
              </Button>
            )}
            {closeButton && (
              <Button onClick={onClose} size={isMobile ? 'small' : 'midium'}>
                닫기
                {!isMobile && disableEscapeKeyDown !== true ? (
                  <span
                    style={{
                      marginLeft: 6,
                      backgroundColor: 'rgb(0 0 0 / 5%)',
                      paddingLeft: 4,
                      paddingRight: 4,
                      borderRadius: 4,
                      fontWeight: 'bolder',
                      fontSize: '0.8em',
                      verticalAlign: 'middle'
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{ fontSize: '0.8em' }}
                    >
                      esc
                    </Typography>
                  </span>
                ) : (
                  ''
                )}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}
export default compose(withStyles(styles))(
  withCompWidth(ComponentPopup, { enabled: true })
)
