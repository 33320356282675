import React from 'react'
import escapeStringRegexp from 'escape-string-regexp'
import config from 'config'
import { isEmptyString, isObject } from 'lib/detectType'
import ReplaceUrlToLink from 'components/molecules/ReplaceUrlToLink'

const getParseData = str => {
  return str.replace(/[\s|/t|\n]/g, '')
}

const openNewTab = url => {
  window.open(url, '_blank')
}

const getRegExp = text => {
  return new RegExp(escapeStringRegexp(getParseData(text)))
}

export const findErrorSolution = (responseData, options = {}) => {
  const { credential = {}, actions = {} } = options
  const {
    // auth,
    token
  } = credential
  const {
    ConfirmActions
    // PopupActions,
    // ChannelTalkActions
  } = actions
  const errorMessage = responseData?.errorMessage || responseData?.message
  if (isEmptyString(errorMessage)) return
  const parseMsg = getParseData(errorMessage)
  const result = {}
  if (
    getRegExp('해당 계정의 리소스에 접근할 수 있는 권한이 없습니다.').test(
      parseMsg
    )
  ) {
    result.callback = () => {
      openNewTab(
        'https://guide.solapi.com/c131c843-fe94-4394-9ee3-12efa7c58bef'
      )
    }
  } else if (getRegExp('존재하지 않는 카카오톡 채널입니다').test(parseMsg)) {
    result.callback = () => {
      openNewTab('/kakao/guide')
    }
  } else if (getRegExp('이미 사용중인 카카오톡 채널입니다').test(parseMsg)) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message:
          '카카오 채널이 이미 상위딜러사에 연동되어 있을 수 있습니다. 관리자에게 채팅 / 전화 문의주시면 별도로 수동 연동 해드리겠습니다.'
      })
    }
  } else if (getRegExp('사용 중인 검색용 아이디입니다').test(parseMsg)) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message: '이미 연동된 검색용 아이디 입니다. 다시 확인해주세요.'
      })
    }
  } else if (getRegExp('카카오톡 채널 상태를 확인 해주세요').test(parseMsg)) {
    result.callback = () => {
      openNewTab('/kakao/guide/4')
    }
  } else if (
    getRegExp('요청한 번호가 카카오톡 채널 관리자 알림 설정 되어있는지').test(
      parseMsg
    )
  ) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message: (
          <ReplaceUrlToLink LinkProps={{ target: '_blank' }}>
            {[
              `1. 카카오 채널 관리자센터 ( https://center-pf.kakao.com/settings/account ) 접속`,
              `2. 우측 상단 [내 정보] 클릭`,
              `3. 관리자 추가인증 설정이 "ON" 상태인지 확인`
            ].join('\n\n')}
          </ReplaceUrlToLink>
        )
      })
    }
  } else if (
    getRegExp('비즈니스 채널 전환이 필요한 프로필입니다').test(parseMsg)
  ) {
    result.callback = () => {
      openNewTab('/kakao/guide/3')
    }
  } else if (
    getRegExp('발신프로필 채널의 고객센터 정보 입력이 필요합니다').test(
      parseMsg
    )
  ) {
    result.callback = () => {
      openNewTab('/kakao/guide/5')
    }
  } else if (getRegExp('3018').test(parseMsg)) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message: [
          `메시지 수신거부, 유심 불일치, 활성 사용자 아님 등의 사유로 알림톡 수신에 실패할 수 있습니다.`,
          `또한 서버와 연결되어 있고, 발송 당일 가입한 사용자를 제외한 최근 7일(168시간) 내에 카카오톡을 사용한 사용자여야 합니다.`,
          `채널 관리자 번호에 대해서 2회선 번호를 사용하시거나 하면 유심 불일치가 되는 경우가 많습니다.`,
          `유심의 전화번호와 로그인 된 카카오톡의 전화번호가 다를 때 발생되는 경우가 대다수이며,`,
          `최근 유심을 변경한 적이 있다면 카카오톡 정보도 수정 부탁 드립니다.`,
          `#. 인증절차`,
          `ㄴ 카카오톡 - 내정보 - 카카오계정 - 전화번호 본인인증`
        ].join('\n\n')
      })
    }
  } else if (getRegExp('잦은 호출로 제한되었습니다').test(parseMsg)) {
    result.callback = () => {
      const url = config?.isCoolsms
        ? 'https://developers.coolsms.co.kr/references/ratelimit'
        : 'https://developers.solapi.com/references/ratelmit'
      openNewTab(url)
    }
  } else if (
    getRegExp('일일 발송량을 초과하여 발송이 불가능합니다').test(parseMsg)
  ) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        message: `일일 발송량 증가 후 재시도 필요합니다.\n[일일발송량] 페이지에서 요청가능.\n지금 바로 페이지를 열까요?`,
        onConfirm: () => {
          openNewTab('/quota')
        }
      })
      // openNewTab(
      //   'https://guide.solapi.com/9ee877fe-8096-4a14-9b9c-ab8796433885'
      // )
    }
  } else if (
    isObject(token) &&
    getRegExp('계정 ID가 유효하지 않습니다').test(parseMsg)
  ) {
    result.hideMessage = true
  } else if (
    // 토큰 만료
    !isObject(token) &&
    getRegExp('요청중 문제가 발생했습니다. 잠시후 재시도해주세요').test(
      parseMsg
    )
  ) {
    result.hideMessage = true
  } else if (
    getRegExp('결제한도초과').test(parseMsg) ||
    getRegExp('승인한도초과').test(parseMsg)
  ) {
    result.callback = () => {
      openNewTab(
        'https://guide.solapi.com/f6e4b857-28d0-4405-ab18-0507b213430e#f6e4b857-28d0-4405-ab18-0507b213430e'
      )
    }
  } else if (getRegExp('카드 결제가 거부').test(parseMsg)) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message: `"해외 원화 결제'가 가능한 카드인지 카드사 별도 문의가 필요합니다.\n(일반 해외 결제와 다름)`
      })
    }
  } else if (getRegExp('요청을 보낼 권한이 없습니다').test(parseMsg)) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message: `커머스 API 필수 권한이 없는 경우 표기될 수 있습니다.\n애플리케이션 등록 시 권한에서 “상품”, “주문 판매자”, “판매자정보”는 필수로 추가해주세요!`,
        onConfirm: () => {
          openNewTab(
            'https://solapi.com/guides/auto-commerce/#3-%EC%8A%A4%ED%86%A0%EC%96%B4-%EC%95%A0%ED%94%8C%EB%A6%AC%EC%BC%80%EC%9D%B4%EC%85%98-%EB%93%B1%EB%A1%9D'
          )
        }
      })
    }
  } else if (
    getRegExp('바로연결은 챗봇 또는 상담톡을 사용하는 채널만').test(parseMsg)
  ) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message: `해당 카카오채널은 챗봇 또는 상담톡을 사용하는 채널이 아닙니다.\n챗봇 또는 상담톡을 연동 후 사용가능합니다.`
      })
    }
  } else if (getRegExp('템플릿 정보를 찾을 수 없습니다').test(parseMsg)) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message: `해당 템플릿은 공유 템플릿 이거나, 수정 권한이 없는 템플릿 일 수 있습니다.`
      })
    }
  } else if (
    responseData?.errorCode === 'ExternalError' &&
    getRegExp('카카오 서비스 오류 리턴(링크가 유효하지 않습니다').test(parseMsg)
  ) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        popupMessage: true,
        message: `템플릿에 입력된 앱 또는 웹링크를 확인해주세요. 만약 해결이 어려운 경우 템플릿 이름과 함께 관리자에게 문의해주세요.`
      })
    }
  } else if (getRegExp('체험하기 계정은 사용할 수 없습니다').test(parseMsg)) {
    result.callback = () => {
      ConfirmActions.show({
        severity: 'info',
        message: `체험하기 계정은 사용이 어렵습니다. 실계정 전환 페이지로 이동할까요?`,
        onConfirm: () => {
          openNewTab('/trial/convert')
        }
      })
    }
  }
  return result
}
