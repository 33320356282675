import { Map } from 'immutable'
import * as coolapi from 'lib/api/coolapi'
import { requestHandler } from 'lib'
const { createRedux } = requestHandler
const initialState = Map({})
const handler = {}

handler.getMyIP = {
  payloadCreator: coolapi.getMyIP
}

handler.login = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: coolapi.login
}

handler.getAllowedIpAddrs = {
  payloadCreator: coolapi.getAllowedIpAddrs
}

handler.getUser = {
  options: {
    response: {
      error: {
        ignore: [404] // (array or boolean) default: false
      }
    }
  },
  payloadCreator: coolapi.getUser
}

handler.createAllowedIpAddr = {
  payloadCreator: coolapi.createAllowedIpAddr
}

handler.createAllowedIpAddr = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: coolapi.createAllowedIpAddr
}

handler.deleteAllowedIpAddr = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: coolapi.deleteAllowedIpAddr
}

handler.updateUser = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: coolapi.updateUser
}

handler.getPayInfo = {
  options: {
    response: {
      error: {
        ignore: [404] // (array or boolean) default: false
      }
    }
  },
  payloadCreator: coolapi.getPayInfo
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
