import { Map } from 'immutable'
import { requestHandler } from 'lib'
import * as oauthAPI from 'lib/api/oauth2'
const { createRedux } = requestHandler
const initialState = Map({})

const handler = {}

handler.login = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: oauthAPI.login
}

handler.getClientInfo = {
  payloadCreator: oauthAPI.getClientInfo
}

handler.getMyAllowedClients = {
  payloadCreator: oauthAPI.getMyAllowedClients
}

handler.getScopeInfo = {
  payloadCreator: oauthAPI.getScopeInfo
}

handler.recreateSecret = {
  payloadCreator: oauthAPI.recreateSecret
}

handler.deleteMyAllowedScopes = {
  options: { debounce: { wait: 500 } },
  payloadCreator: oauthAPI.deleteMyAllowedScopes
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)

export const actions = reduxActions
export default reduxReducers
