const cookies = require('js-cookie')
const jwt = require('jsonwebtoken')
const merge = require('lodash/merge')
const { appName } = jwt.decode(cookies.get('AHID')) || {}
const naverCommerceApiKeyMap = require('./source/naverCommerceKeyMap.js')

const getCookieDomain = () => {
  const hostname = window.location.hostname.split('.')
  const domain = [hostname.pop(), hostname.pop()].filter(d => d).reverse()
  return domain.length > 1 ? `.${domain.join('.')}` : domain?.[0] ?? ''
}
let config = {
  env: process.env.NODE_ENV || 'development',
  isDev: process.env.NODE_ENV !== 'production',
  isIntegration: process.env.REACT_APP_INTEGRATION === '1',
  isAdmin: process.env.REACT_APP_ADMIN === '1',
  isCoolsms: process.env.REACT_APP_COOLSMS === '1',
  isMysite: appName !== undefined,
  basename: process.env.PUBLIC_PATH,
  serviceName: appName || '솔라피',
  cookieDomain:
    process.env.NODE_ENV !== 'production' ? 'localhost' : getCookieDomain(),
  homePage: 'https://solapi.com',
  isBrowser: typeof window !== 'undefined',
  imp: 'imp87845006',
  logrocket: 'e3kvf2/nurigo',
  kcpPageHost: 'https://admin.kcp.co.kr',
  supportPageHost: 'https://support.solapi.com',
  consolePageHost: 'https://console.solapi.com',
  guidePageUrl: 'https://guide.solapi.com',
  zendeskApiHost: 'https://solapi.zendesk.com',
  zendeskNotice: '360001489113',
  googleRecaptchaSiteKey: '6LcEe4kUAAAAAMOZFJW4i11LGz8WKmwFZ0uT2ini',
  supportUrl: 'https://support.solapi.com',
  support: {
    phoneNumber: '1577-1603',
    email: 'contact@nurigo.net'
  },
  disqusShortName: 'testsite-eank2g1iii',
  getRequestHeaders: () => {
    return { Authorization: `Bearer ${cookies.get('CSAK')}` }
  },
  getAhid: () => {
    const ahid = jwt.decode(cookies.get('AHID'))
    return ahid
  },
  categories: {
    ENTER: '엔터테인먼트',
    BUSINESS: '비즈니스',
    ETC: '기타'
  },
  loginPage: '/oauth2/login',
  loginPath: '/oauth2/v1/login',
  logoutPath: '/oauth2/v1/logout',
  homePath: '/dashboard',
  pageTitle: {
    login: '로그인',
    storage: '스토리지',
    approval: '사용자 권한 요청',
    credentials: 'API Key 관리',
    messageLog: '문자 발송 내역',
    statistics: '사용 통계',
    mfaRequest: '2차 인증 요청',
    mfaVerify: '2차 인증 확인',
    businessAdmin: '사업자 목록',
    taxAdmin: '세금계산서 관리자 페이지',
    balanceAdmin: '잔액 목록',
    pricingAdmin: '단가조정 관리페이지',
    balance: '잔액 충전 / 관리',
    balanceHistory: '잔액 사용 내역',
    withdrawalHistory: '출금 요청 내역',
    apiSignature: 'API Signature 발급기',
    error: '오류가 발생했습니다',
    quota: '일일 문자 발송량 제한',
    unsubscribe: '수신 거부 리스트',
    dashboard: '대시보드',
    webhook: 'Webhooks',
    authSettings: '간편 인증 설정',
    authHistory: '간편 인증 내역',
    senderIdAdminList: '발신번호 목록 관리자 페이지',
    whiteListPage: '화이트 리스트 관리자 페이지',
    scheduler: '스케줄 관리자 페이지',
    accountManagement: '계정 관리 페이지',
    appsAdmin: '앱 리스트 관리자 페이지',
    appsMe: '앱 리스트 페이지',
    appDetailUserPage: '앱 수정 페이지',
    appScopesAdminPage: '앱 권한 관리자 페이지',
    profitHistory: '정산 내역',
    profitHistoryDetail: '정산 내역 자세히 보기'
  },
  appStage: {
    LIVE: '배포됨',
    DEVELOP: '개발중'
  },
  appStatus: {
    ACTIVE: '활성화',
    BLOCKED: '차단됨'
  },
  groupStatus: {
    PENDING: '사용자요청대기',
    FAILED: '접수실패',
    SENDING: '발송요청완료',
    DELETED: '삭제',
    COMPLETE: '처리완료',
    SCHEDULED: '예약발송대기중',
    PROCESSING: '발송처리중',
    'SYSTEM-ERROR': '시스템오류',
    'BULK-REQUESTED': '발송요청완료',
    'SEND-REQUESTED': '발송요청완료',
    대기중: '발송처리중'
  },
  groupStatusDesc: {
    PENDING:
      '사용자가 아직 발송 요청하지 않았습니다. 자동으로 발송되지 않으며 별도 요청이 없으면 24시간 후 삭제됩니다.',
    FAILED:
      '예약이 취소된 경우 또는 그룹 생성에 실패한 경우 일 수 있습니다. 실패 사유는 다양하므로 그룹 이력을 확인하세요.',
    SENDING:
      '정상적으로 접수가 완료된 메시지를 이통사로 발송 요청한 상태입니다. 메시지 타입에 따라서 해당 상태가 최대 72시간 동안 지속될 수 있습니다.',
    DELETED:
      '그룹이 삭제된 상태입니다. 24시간 내 발송 요청하지 않으면 자동으로 그룹을 삭제합니다.',
    COMPLETE:
      '이통사 리포트를 전달받은 후 환급 및 통계 반영이 모두 완료된 상태입니다.',
    SCHEDULED:
      '예약 발송 대기중 상태의 그룹입니다. 지정한 예약일에 발송 요청하며, 발송 당시에 잔액부족, 일일발송량초과 등의 사유로 발송에 실패할 수 있으므로 유의하세요.',
    PROCESSING: '그룹 발송을 위해 내부에서 발송처리중인 상태입니다.',
    'SYSTEM-ERROR':
      '내부 오류입니다. 자세한 사항은 그룹 이력을 참조하시되, 당사에 문의하실 수 있습니다.',
    'BULK-REQUESTED':
      '(대량발송처리) 정상적으로 접수가 완료된 메시지를 이통사로 발송 요청한 상태입니다.',
    'SEND-REQUESTED':
      '(단건발송처리) 정상적으로 접수가 완료된 메시지를 이통사로 발송 요청한 상태입니다.',
    대기중: '그룹 발송을 위해 내부에서 발송처리중인 상태입니다.'
  },
  rechargeMethod: {
    CARD: 'KCP 카드 결제',
    STRIPE: 'STRIPE',
    'ACCOUNT-TRANSFER': '계좌이체'
  },
  balanceHistoryType: {
    RECHARGE: '충전',
    REWARD: '시스템 오류 보상',
    MANUAL: '관리자 임의',
    SYSTEM: '시스템 자동 지급',
    TRANSFER: '잔액 이전',
    'APP-PROFIT': '앱 수익금',
    'AFFILIATE-PROFIT': '추천인 수익금',
    'SERVICE-CHARGE': '서비스 이용',
    WITHDRAW: '출금 신청'
  },
  senderIdDuplicatedStatus: {
    PENDING: '대기',
    REJECTED: '거절',
    APPROVED: '승인'
  },
  senderIdLimitationStatus: {
    PENDING: '대기',
    REJECTED: '거절',
    APPROVED: '승인'
  },
  senderIdAuthMethod: {
    'KR-ARS': '국내 전화 인증',
    ARS: '전화 인증',
    SMS: '문자 인증',
    HASH: '해시 인증',
    DOCUMENT: '서류 인증',
    ADMIN: '관리자 인증',
    'SELF-CERT': '문자본인인증'
  },
  senderIdApprovalStatus: {
    PENDING: '대기',
    APPROVED: '승인됨',
    REJECTED: '거절됨'
  },
  senderIdStatus: {
    ACTIVE: '활성화',
    INACTIVE: '비활성화',
    DUPLICATED: '타 계정에서 사용중',
    BLOCKED: '차단됨',
    PENDING: '인증필요',
    EXPIRED: '만료됨',
    DELETED: '삭제됨',
    OVERLIMIT: '개수제한해제 필요'
  },
  senderIdStatusButtonInfo: {
    ACTIVE: {
      name: '만료일갱신',
      color: 'primary',
      variant: 'contained',
      tooltipTitle: '새롭게 발신번호 재인증합니다.',
      disabledTooltipTitle: `인증서류를 관리자에게 제출했습니다. 관리자 승인을 기다리고 있습니다.`
    },
    INACTIVE: {
      name: '활성화',
      color: 'primary',
      variant: 'outlined',
      tooltipTitle:
        '해당 번호는 비활성화 되어있습니다. 활성화 하려면 클릭하세요.'
    },
    DUPLICATED: {
      name: '중복사용요청',
      color: 'primary',
      variant: 'contained',
      tooltipTitle:
        '해당 번호는 타 계정에서 이미 이용중입니다. 활성화 하려면 클릭하세요.',
      disabledTooltipTitle: `중복사유를 이미 관리자에게 제출했습니다. 관리자 승인을 기다리고 있습니다.`
    },
    PENDING: {
      name: '번호인증',
      color: 'primary',
      variant: 'contained',
      tooltipTitle:
        '해당 번호는 아직 인증받지 않았습니다. 문자 발송에 이용하려면 먼저 소유자 인증을 받아야합니다.',
      disabledTooltipTitle: `인증서류를 관리자에게 제출했습니다. 관리자 승인을 기다리고 있습니다.`
    },
    EXPIRED: {
      name: '만료일갱신',
      color: 'primary',
      variant: 'contained',
      tooltipTitle:
        '해당 번호는 만료되어 더 이상 사용이 불가합니다. 문자 발송에 이용하려면 소유자 재인증을 받아야합니다.',
      disabledTooltipTitle: `인증서류를 이미 관리자에게 제출했습니다. 관리자 승인을 기다리고 있습니다.`
    },
    OVERLIMIT: {
      name: '개수제한해제',
      color: 'primary',
      variant: 'contained',
      tooltipTitle:
        '최대로 등록 가능한 발신번호제한 개수를 초과했습니다. 먼저 관리자에게 발신번호 개수제한을 증가 요청하세요.'
    }
  },
  senderIdDuplicateStatus: {
    PENDING: '승인대기',
    REJECTED: '반려',
    APPROVED: '승인'
  },
  deferredPaymentStatus: {
    PENDING: '신청진행중',
    INACTIVE: '일시정지',
    ACTIVE: '사용중',
    DELETED: '사용취소'
  },
  deferredPaymentActionsRequiredStatus: {
    PENDING: '신청진행중',
    CHECKING: '검수요청',
    APPROVED: '승인됨',
    REJECTED: '거절됨'
  },
  notiChannels: {
    EMAIL: '이메일',
    SMS: '문자메시지',
    ATA: '알림톡',
    CTA: '친구톡',
    SLACK: '슬랙'
  },
  getTokenData: () => {
    const CSAK = cookies.get('CSAK')
    return jwt.decode(CSAK)
  },
  pages: {
    login: '/oauth2/login',
    trial: '/oauth2/trial-login',
    resetPassword: '/reset-password',
    signUp: '/signup',
    appList: '/apps'
  },
  blocksender: '0805004233',
  withdraw: {
    defaultRejectMemo: '궁금하신 점이 있으시면 1:1문의를 이용해주세요.'
  },
  oauth: {
    decisionPath: '/oauth2/v1/authorize/decision',
    forgetPasswordPath: 'https://solapi.com/reset-password',
    signUpPath: 'https://solapi.com/signup'
  },
  mfa: {
    requestPath: '/2fa/v1/request',
    callArsPath: '/2fa/v1/ars/call',
    callVerifyPath: '/2fa/v1/ars/verify',
    verifyPath: '/2fa/v1/verify',
    resendSecretCodePath: '/2fa/v1/resend',
    enableOtpPath: '/2fa/v1/otp/enable',
    label: {
      PWD: '비밀번호',
      SMS: '인증코드',
      eOTP: '인증코드',
      gOTP: '인증코드',
      ARS: '인증코드'
    },
    title: {
      PWD: '계속하시려면 비밀번호를 입력하세요.',
      SMS: '계속하시려면 휴대폰으로 발송된 인증코드를 입력하세요.',
      eOTP: '계속하시려면 이메일로 발송된 인증코드를 입력하세요.',
      gOTP: '계속하시려면 Google OTP 앱의 인증코드를 입력하세요.',
      ARS: '계속하시려면 ARS에서 들으신 인증코드를 입력하세요.'
    }
  },
  accountStatus: {
    ACTIVE: '활성화',
    INACTIVE: '휴면 계정',
    DELETED: '삭제됨'
  },
  memberStatus: {
    ACTIVE: '활성화',
    UNVERIFIED: '미인증',
    INACTIVE: '휴면',
    DELETED: '삭제됨'
  },
  kakaoPlusFriendGroupStatus: {
    PENDING: '생성대기중',
    INSPECTING: '검수중',
    REJECTED: '반려됨',
    APPROVED: '등록완료',
    DELETED: '삭제됨'
  },
  kakaoTemplateStatus: {
    PENDING: '검수요청필요',
    INSPECTING: '검수중',
    REJECTED: '반려됨',
    APPROVED: '등록완료',
    DELETED: '삭제됨'
  },
  kakaoTemplateStatusDesc: {
    PENDING:
      '알림톡 발송을 위해 카카오에 템플릿을 검수 요청이 가능한 상태입니다.',
    INSPECTING:
      '검수 요청을 보냈습니다. 검수 결과 도착까지 1~3 영업일이 소요됩니다.',
    REJECTED: '검수 요청 결과 반려되었습니다. 수정 후 재검수 요청해주세요.',
    APPROVED: '템플릿 등록이 완료되었습니다. 알림톡 발송이 가능한 상태입니다.'
  },
  naverTemplateStatus: {
    PENDING: '검수요청필요',
    INSPECTING: '검수중',
    APPROVED: '등록완료',
    REJECTED: '반려됨'
  },
  rcsTemplateStatus: {
    PENDING: '검수요청필요',
    INSPECTING: '검수중',
    REJECTED: '반려됨',
    APPROVED: '등록완료'
  },
  rcsCardType: {
    cell: '표/목록',
    description: '텍스트'
  },
  withdrawalStatus: {
    PENDING: '진행중',
    COMPLETE: '처리완료',
    FAILED: '요청실패'
  },
  bankList: [
    {
      code: '002',
      name: '산업',
      fullName: 'KDB 산업은행'
    },
    {
      code: '003',
      name: '기업',
      fullName: 'IBK 기업은행'
    },
    {
      code: '004',
      name: '국민',
      fullName: 'KB 국민은행'
    },
    {
      code: '007',
      name: '수협',
      fullName: '수협중앙회'
    },
    {
      code: '011',
      name: '농협',
      fullName: 'NH 농협은행'
    },
    {
      code: '020',
      name: '우리',
      fullName: '우리은행'
    },
    {
      code: '023',
      name: 'SC 제일',
      fullName: 'SC 제일은행'
    },
    {
      code: '027',
      name: '씨티',
      fullName: '한국시티은행'
    },
    {
      code: '031',
      name: '대구',
      fullName: '대구은행'
    },
    {
      code: '032',
      name: '부산',
      fullName: '부산은행'
    },
    {
      code: '034',
      name: '광주',
      fullName: '광주은행'
    },
    {
      code: '035',
      name: '제주',
      fullName: '제주은행'
    },
    {
      code: '037',
      name: '전북',
      fullName: '전북은행'
    },
    {
      code: '039',
      name: '경남',
      fullName: '경남은행'
    },
    {
      code: '081',
      name: 'KEB 하나',
      fullName: 'KEB 하나은행'
    },
    {
      code: '088',
      name: '신한',
      fullName: '신한은행'
    }
  ],
  gaTrackingID: 'UA-132766961-1',
  mainTrackingID: `G-28YXQXMX54`,
  gtmID: 'GTM-5RHHXWV4',
  importUserCode: 'imp87845006',
  authTypes: {
    PASSWORD: '2차 비밀번호 인증',
    'KR-ARS': '전화 인증',
    SMS: '문자 인증',
    'EMAIL-OTP': '이메일 인증',
    HASH: '문자 해시 인증',
    ARS: 'ARS 전화 인증',
    'SELF-CERT': '문자 본인 인증',
    // 명의 도용 방지
    'MULTI-CERT': '간편 본인 인증'
  },
  defaultAuthTypeOrderPriority: [
    'PASSWORD',
    'MULTI-CERT',
    'SELF-CERT',
    'KR-ARS',
    'SMS',
    'HASH',
    'ARS',
    'EMAIL-OTP'
  ],
  authTypePriority: {
    PASSWORD: '2차 비밀번호 인증',
    'KR-ARS': '전화 인증',
    SMS: '문자 인증',
    'EMAIL-OTP': '이메일 인증',
    HASH: '문자 해시 인증',
    ARS: 'ARS 전화 인증',
    'SELF-CERT': '문자 본인 인증',
    // 명의 도용 방지
    'MULTI-CERT': '간편 본인 인증'
  },
  messageType: {
    sms: '단문문자',
    lms: '장문문자',
    mms: '사진문자',
    ata: '카카오 알림톡',
    cta: '카카오 친구톡',
    cti: '친구톡 이미지',
    nsa: '네이버 스마트알림',
    rcs_sms: 'RCS 단문',
    rcs_lms: 'RCS 장문',
    rcs_mms: 'RCS 사진',
    rcs_tpl: 'RCS 템플릿',
    rcs_itpl: 'RCS 이미지 템플릿'
  },
  messageTypeInfo: {
    sms: {
      name: '단문문자',
      description:
        '내용이 90bytes(한글 약 45자) 이하인 문자입니다. 24시간내로 이통사로부터 리포트를 받습니다.',
      backgroundColor: '#b6ddff'
    },
    lms: {
      name: '장문문자',
      description:
        '내용이 90bytes(한글 약 45자)를 초과하는 문자입니다. 72시간내로 이통사로부터 리포트를 받습니다. 발송중 상태로 오래 남아있을 수 있습니다. (최대 72시간)',
      color: '#fff',
      backgroundColor: '#6fadfc'
    },
    mms: {
      name: '사진문자',
      description:
        '이미지가 첨부된 문자입니다. 72시간내로 이통사로부터 리포트를 받습니다. 발송중 상태로 오래 남아있을 수 있습니다. (최대 72시간)',
      color: '#fff',
      backgroundColor: '#3f88f7'
    },
    ata: {
      name: '카카오 알림톡',
      description:
        '카카오톡으로 수신받는 알림톡입니다. 채널에 친구 추가가 되어있지 않아도 전화번호만 이용하여 카카오톡 발송이 가능하지만, 알림톡 템플릿 사전 등록이 필요합니다.',
      backgroundColor: '#ffe020'
    },
    cta: {
      name: '카카오 친구톡',
      description:
        '카카오톡으로 수신받는 친구톡입니다. 채널에 친구추가가 되어있는 사람에 한해 발송됩니다. 야간 발송 제한으로 08시 ~ 20시50분 사이에만 발송이 가능합니다.',
      color: '#fff',
      backgroundColor: '#9d6f1b'
    },
    cti: {
      name: '친구톡 이미지',
      description:
        '이미지를 첨부한 친구톡입니다. 채널에 친구추가가 되어있는 사람에 한해 발송됩니다. 야간 발송 제한으로 08시 ~ 20시50분 사이에만 발송이 가능합니다.',
      color: '#fff',
      backgroundColor: '#ffa82f'
    },
    nsa: {
      name: '네이버 스마트 알림',
      description:
        '네이버 앱으로 수신받는 알림입니다. 발송전에 템플릿 등록이 필요합니다.',
      color: '#fff',
      backgroundColor: '#00c73c'
    },
    rcs_sms: {
      name: 'RCS 단문문자',
      description:
        'RCS는 새로운 문자 발송 규격입니다. 여러 이미지 전송하거나 문자에 버튼을 사용할 수 있는 등 마케팅에 유용하게 사용할 수 있습니다.',
      zIndex: 1,
      backgroundColor: '#b6ddff'
    },
    rcs_lms: {
      name: 'RCS 장문문자',
      description:
        'RCS는 새로운 문자 발송 규격입니다. 여러 이미지 전송하거나 문자에 버튼을 사용할 수 있는 등 마케팅에 유용하게 사용할 수 있습니다.',
      color: '#fff',
      backgroundColor: '#6fadfc'
    },
    rcs_mms: {
      name: 'RCS 사진문자',
      description:
        'RCS는 새로운 문자 발송 규격입니다. 여러 이미지 전송하거나 문자에 버튼을 사용할 수 있는 등 마케팅에 유용하게 사용할 수 있습니다.',
      color: '#fff',
      backgroundColor: '#3f88f7'
    },
    rcs_tpl: {
      name: 'RCS 템플릿 문자',
      description:
        'RCS는 새로운 문자 발송 규격입니다. 여러 이미지 전송하거나 문자에 버튼을 사용할 수 있는 등 마케팅에 유용하게 사용할 수 있습니다.',
      backgroundColor: '#cfb6ff'
    },
    fax: {
      name: '팩스',
      description: '인터넷 팩스',
      backgroundColor: '#ccc'
    },
    voice: {
      name: '음성메시지',
      description: '전화 음성메시지',
      backgroundColor: '#ccc'
    }
  },
  kakaoBtnTypes: {
    AC: {
      name: '채널추가',
      desc: '발신자의 채널을 추가할 수 있는 버튼입니다. 해당 버튼 사용 시, 광고 보조 문구가 사용됩니다.\n이미 추가된 채널인 경우 수신자에게 표시되지 않습니다.',
      allowDuplicate: false,
      allowQuickReply: false,
      onlyConsultChannel: false
    },
    WL: {
      name: '웹링크',
      desc: `버튼 클릭 시, 인앱 브라우저에서 웹페이지 실행
- Mobile(필수) / PC(선택)에서 실행할 URL을 각각 설정
- URL 링크를 변수로 설정할 수 있으며, 링크를 변수설정 하는 과정에서 프로토콜은(http) 고정으로 입력해야함
예시) http://#{url}, https://#{LINK}, https://nurigo.net
      `,
      allowDuplicate: true,
      allowQuickReply: true,
      onlyConsultChannel: false
    },
    AL: {
      name: '앱링크 (URL Scheme)',
      desc: '"market://"과 같은 문자열을 url scheme이라 부릅니다. url scheme을 통해 앱의 특정 기능 또는 페이지를 실행합니다.',
      allowDuplicate: true,
      allowQuickReply: true,
      onlyConsultChannel: false
    },
    DS: {
      name: '배송조회',
      desc: `알림톡 템플릿 내용에 택배사, 송장번호가 포함된경우 카카오 검색 배송조회 페이지로 이동하는 배송조회하기 버튼을 자동으로 생성

배송조회 버튼이 노출되는 택배사
- 지원 가능 택배사 : 우체국택배, 로젠택배, 일양로지스, FedEx, 한진택배, 경동택배, 합동택배, 롯데택배, 세방택배, 한덱스택배, 농협택배, 호남택배, 천일택배, 건영택배, 대신택배, CU편의점택배, CVSnet편의점택배, TNT ExpressUSPS, EMS, DHL, 굿투럭

 (이외 택배사의 경우 배송조회 버튼이 노출되지 않음)`,
      allowDuplicate: false,
      allowQuickReply: false,
      onlyConsultChannel: false
    },
    BK: {
      name: '봇키워드',
      desc: '(상담톡전용) 클릭 시 버튼명을 그대로 프로필 관리자에게 카카오톡 채팅으로 전달합니다.',
      allowDuplicate: true,
      allowQuickReply: true,
      onlyConsultChannel: true
    },
    MD: {
      name: '메시지전달',
      desc: '(상담톡전용) 클릭 시 받은 알림톡 내용을 그대로 프로필 관리자에게 전달합니다.',
      allowDuplicate: true,
      allowQuickReply: false,
      onlyConsultChannel: true
    },
    BC: {
      name: '상담톡전환',
      desc: '(상담톡전용) 상담톡을 이용하는 고객 대상으로 진행되는 버튼 서비스 입니다. 상담톡 연동이 진행됩니다.',
      allowDuplicate: true,
      allowQuickReply: true,
      onlyConsultChannel: true
    },
    BT: {
      name: '봇전환',
      desc: '(상담톡전용) 챗봇을 이용하는 고객 대상으로 진행되는 버튼 서비스 입니다. 봇 호출 시 사용됩니다.',
      allowDuplicate: true,
      allowQuickReply: true,
      onlyConsultChannel: true
    }
  },
  naverBtnTypes: {
    WL: {
      name: '웹링크',
      desc: '버튼 클릭시 특정 웹사이트를 엽니다.'
    }
  },
  kakaoTemplateMessageType: {
    BA: '기본형',
    EX: '부가정보형',
    AD: '채널추가형',
    MI: '복합형(부가정보+채널추가)'
  },
  kakaoTemplateEmphasizeType: {
    NONE: '기본형',
    TEXT: '강조표기형',
    IMAGE: '이미지첨부형',
    ITEM_LIST: '아이템리스트형'
  },
  kakaoTemplateEmphasizeTypeDescription: {
    NONE: `특별한 강조 없이 글자만 포함하는 알림톡을 발송할 때 사용합니다.\n간단한 알림이나 안내문구 등 정갈한 안내 표현이 가능합니다.`,
    TEXT: '알림톡에 강조 제목을 표기합니다.\n강조 하고싶은 문구가 있는 경우 주로 사용하며, 수신자에게 중요한 내용을 바로 인지시킬 수 있습니다.',
    IMAGE:
      '알림톡에 이미지를 첨부합니다.\n차별화된 브랜딩을 제공하고 싶은 경우 주로 사용하며, 완성도 높은 메시지를 전달하여 기업 신뢰도를 한층 높일 수 있습니다.',
    ITEM_LIST:
      '알림톡에 리스트를 추가합니다.\n영수증이나 구매물품 등 항목 표현이 필요한 경우 주로 사용하며, 표 형식으로 출력되어 정리된 정보를 한눈에 볼 수 있습니다.'
  },
  authStatus: {
    WAITING: '인증 대기중',
    PASSED: '인증 완료',
    EXPIRED: '만료됨'
  },
  KcpPayMethod: {
    100000000000: '국내카드결제',
    '010000000000': '계좌이체'
    // '001000000000': '가상계좌'
  },
  ImportPayMethod: {
    card: '카드 결제',
    trans: '실시간계좌이체',
    vbank: '가상계좌'
  },
  developerPageHost: 'https://developers.solapi.com/',
  featureStatus: {
    OPENED: '등록',
    PLANNED: '예정 ',
    PROCESSING: '진행',
    CLOSED: '닫힘',
    DONE: '완료'
  },
  featureStatusColor: {
    OPENED: '#ccc',
    PLANNED: '#ffa12a',
    PROCESSING: '#2860c6',
    CLOSED: '#ec4c4c',
    DONE: '#1c9e0e'
  },
  inquiryStatus: {
    PROCESSING: '진행중',
    DONE: '완료'
  },
  purplebookVariableMaxValueLength: 1000,
  purplebookTab: {
    textmessage: {
      tabIndex: 0,
      title: '문자메시지',
      ticker: '문자',
      mainColor: '#6a1b9a',
      darkColor: '#4a148c'
    },
    ata: {
      tabIndex: 1,
      title: '알림톡',
      ticker: '알림톡',
      mainColor: '#795548',
      darkColor: '#3e2723'
    },
    cta: {
      tabIndex: 2,
      title: '친구톡',
      ticker: '친구톡',
      mainColor: '#546e7a',
      darkColor: '#37474f'
    },
    rcs: {
      tabIndex: 5,
      title: 'RCS 문자',
      ticker: 'RCS',
      mainColor: '#2c398a',
      darkColor: '#1a2255'
    },
    nsa: {
      tabIndex: 3,
      title: '네이버톡톡',
      ticker: '네이버',
      mainColor: '#00a732',
      darkColor: '#009d2f'
    },
    foreign: {
      tabIndex: 4,
      title: '해외문자',
      ticker: '해외',
      mainColor: '#6a1b9a',
      darkColor: '#4a148c'
    }
  },
  channelTalkPluginKey: 'b1928442-5a21-479c-a684-5faabecdac7e',
  role: [
    { key: 'OWNER', name: '계정관리자' },
    { key: 'DEVELOPER', name: '개발자' },
    { key: 'MEMBER', name: '구성원' }
  ],
  supportStartTime: 9,
  supportEndTime: 17,
  blockGroupStatus: {
    ACTIVE: '활성화',
    INACTIVE: '비활성화'
  },
  // kgMID: 'INIiasTest',
  // kgApiKey: 'TGdxb2l3enJDWFRTbTgvREU3MGYwUT09'
  kgMID: 'solapicom0',
  kgApiKey: '066bf50e2ba4e29f2568326c249888ae',
  webhookDocumentPage: 'https://developers.solapi.com/references/webhook/',
  shortUrlProtocol: 'https://',
  shortUrlDomain: 'solap.io',
  shortCampaignPlanId: 'CA01PL240911032747230PjVDaSaDkqj',
  commerceProPlanId: 'CA01PL240412090047963ggXznPYkfVq',
  commerceDefaultKakaoTpls: [
    'KA01TP241219015223409ii4bH6m3bBM',
    'KA01TP240425020349490v7QKZTtzN4F',
    'KA01TP240319061842504KFvEgoEW0vO',
    'KA01TP240319084043562UPLT17evCs9',
    'KA01TP240319084310084BgjwCBtfwSx'
  ],
  naverCommerceApiKeyMap,
  naverCommerce: {
    targetStatus: {
      PAYED: { label: '결제완료' },
      PAYMENT_WAITING: { label: '결제대기' },
      PREPARING_PRODUCT: { label: '상품준비중' },
      DELIVERING: { label: '배송중' },
      DELIVERED: { label: '배송완료' },
      PURCHASE_DECIDED: { label: '구매 확정' },
      EXCHANGED: { label: '교환' },
      CANCELED: { label: '취소' },
      RETURNED: { label: '반품' },
      CANCELED_BY_NOPAYMENT: { label: '미결제 취소' }
    }
  },
  naverSmartStoreSolutionUrl: `https://solution.smartstore.naver.com/ko/solution/SOL_7h2paWGgAzfvj1TBLeCzKl/detail`,
  solapiOfficialKakaoChannelGroups: [
    `KA01GI240319034741327rrlRuPzyowr`,
    `KA01GI221025035335246N7g6kdXERwJ`
  ]
}

if (process.env.NODE_ENV === 'production') {
  const production = require('config/production')
  config = merge(config, production)
}

if (process.env.NODE_ENV === 'development') {
  const development = require('config/development')
  config = merge(config, development)
}

if (process.env.NODE_ENV === 'test') {
  const test = require('config/test')
  config = merge(config, test)
}

if (process.env.REACT_APP_INTEGRATION === '1') {
  const integration = require('config/integration')
  config = merge(config, integration)
}

if (process.env.REACT_APP_COOLSMS === '1') {
  const coolsms = require('config/coolsms')
  config = merge(config, coolsms)
}

if (process.env.REACT_APP_ADMIN === '1') {
  const admin = require('config/admin')
  config = merge(config, admin)
}

if (process.env.REACT_APP_SANDBOX === '1') {
  const sandbox = require('config/sandbox')
  config = merge(config, sandbox)
}

module.exports = config
