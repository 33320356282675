/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
import Lottie from 'react-lottie'
import pick from 'lodash/pick'

// material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

// material ui icon
import CloseIcon from '@material-ui/icons/Close'
import { MdHelpCenter as MdHelpCenterIcon } from 'react-icons/md'

// my components
import withCompWidth from 'commonHoc/withCompWidth'
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
import PopupContainer from 'containers/PopupContainer'
// import UserContainerName from 'containers/UserContainerName'
import CommonButton from 'containers/CommonButtonContainer'

// libraries
import getComponentId from 'lib/getComponentId'
import bindStateToProps from 'lib/bindStateToProps'
import bindActionCreators from 'lib/bindActionCreators'
// import config from 'config'
import ReactGA4 from 'lib/ga4'
import { isFunction } from 'lib/detectType'
// import isEqualProps from 'lib/isEqualProps'
// import {
//   makeStyleObject as ms,
//   getClassName as cn,
//   getComponentByBreakpoint as gc
// } from 'lib/getBreakPoints'
import { actions as coologActions } from 'store/modules/coolog'
import { actions as usersActions } from 'store/modules/users'
import { actions as channelTalkActions } from 'store/modules/channelTalk'
import { actions as popupActions } from 'store/modules/popup'

import warning from 'static/lottie/confirm_warning.json'

/**
 * 서버 요청 실패 시 팝업 컨텐츠
 */
let Component = ({ classes: cls, ...props }) => {
  const {
    CoologActions,
    PopupActions,
    ChannelTalkActions,
    UsersActions,
    solutionResponse = []
  } = props
  const [response, setResponse] = useState(solutionResponse)
  const onClose = key => {
    PopupActions.close({ key })
  }
  const onSupport = async message => {
    const {
      data: { userChatId }
    } = await UsersActions.openChats()
    const {
      data: {
        message: { chatId }
      }
    } = await UsersActions.postMessage({
      userChatId,
      message
    })
    ChannelTalkActions.open({ chatId })
  }
  const onSolutionClick = (responseData, callback) => {
    ReactGA4.event('click_solution_button', {
      errorSolutionClickRequestUrl: `[${responseData?.method}] ${responseData?.url}`,
      errorSolutionClickStatus: responseData?.status,
      errorSolutionClickCode: responseData?.code,
      errorSolutionClickMessage: responseData?.message,
      errorSolutionClickRequestUrlLocation: responseData?.location
    })
    callback()
  }
  useEffect(() => {
    setResponse(solutionResponse)
    solutionResponse.forEach((data = {}) => {
      const { message, solutionCallback, responseData } = data
      const actionId = responseData?.actionId
      const popupKey = `${data?.responseData?.actionId}_action_solution_popup`
      let isClickedSolution = false
      PopupActions.new({
        key: popupKey,
        onExited: () => {
          CoologActions.setState({
            solutionResponse: solutionResponse.filter(
              item => item.responseData.actionId !== actionId
            )
          })
        },
        title: (
          <Grid container wrap="nowrap" spacing={1} alignItems="center">
            <Grid item>
              <span className={cls.iconArea}>
                <Lottie
                  speed={0.1}
                  options={{
                    speed: 0.1,
                    loop: true,
                    autoplay: true,
                    animationData: warning,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                />
              </span>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>작은 문제가 있어요. 곧 해결하겠지만요!</Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  onClose(popupKey)
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        ),
        supportButton: false,
        closeButton: false,
        actionComponent: (
          <Grid container wrap="nowrap" spacing={1} alignItems="center">
            <Grid item xs>
              <CommonButton
                fullWidth
                variant="outlined"
                color="primary"
                wait={1500}
                clickLoading
                onClick={() => {
                  onSupport(
                    [
                      `사용자 메시지 : ${message}`,
                      `response 메시지 : ${responseData?.message}`,
                      `actionId : ${actionId}`,
                      `솔루션 클릭여부 : ${
                        isClickedSolution === true
                          ? '해결방법 클릭함'
                          : '해결방법 클릭안함'
                      }`,
                      ...Object.keys(
                        pick(responseData, [
                          'method',
                          'status',
                          'code',
                          'location',
                          'url',
                          'moduleName',
                          'actionName'
                        ])
                      ).map(key => {
                        return `${key} : ${responseData[key]}`
                      }),
                      `payload:${JSON.stringify(responseData?.payload)}`
                    ].join('\n')
                  )
                }}
              >
                채팅문의
              </CommonButton>
            </Grid>
            <Grid item xs>
              <Button
                fullWidth
                disableElevation
                disabled={!isFunction(solutionCallback)}
                variant="contained"
                color="primary"
                onClick={() => {
                  isClickedSolution = true
                  onSolutionClick(responseData, solutionCallback)
                }}
              >
                <MdHelpCenterIcon
                  style={{
                    fontSize: '1.4em',
                    marginRight: 4
                  }}
                />{' '}
                해결방법
              </Button>
            </Grid>
          </Grid>
        )
      })
    })
  }, [solutionResponse])
  return (
    <div
      data-compid={getComponentId('ResponseErrorResult')}
      className={cls.root}
    >
      {response.map((data = {}) => {
        const { message = '', responseData: res } = data
        return (
          <PopupContainer popupKey={`${res?.actionId}_action_solution_popup`}>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <Typography
                    variant="body2"
                    style={{
                      whiteSpace: 'pre-line',
                      wordBreak: 'break-all',
                      backgroundColor: '#0000000a',
                      minHeight: 50,
                      padding: 8,
                      borderRadius: 4,
                      border: '1px solid #e4e4e4'
                    }}
                  >
                    {message}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </PopupContainer>
        )
      })}
    </div>
  )
}

export const useProps = {
  //  testVar: { default: 'hello world!', type: 'string.isRequired' }
}
Component.defaultProps = setDefaultProps(useProps)
Component.propTypes = setPropTypes(useProps)
Component = withCompWidth(Component, { enabled: false })
Component = connect(
  bindStateToProps({
    coolog: ['solutionResponse']
  }),
  bindActionCreators({
    popupActions,
    channelTalkActions,
    usersActions,
    coologActions
  })
)(Component)

const commonStyles = theme => ({
  root: {},
  iconArea: {
    display: 'inline-block',
    width: 30,
    height: 30,
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  }
})
Component = withStyles(commonStyles)(Component)

export default React.memo(Component)
