import { Map } from 'immutable'
import { feature as featureAPI } from 'lib/api'
import { createRedux } from 'lib/requestHandler'
import FeatureRequestVote from 'components/molecules/FeatureRequestVote'
import FeatureRequestListOption from 'components/molecules/FeatureRequestListOption'
import FeatureRequestViewerStatus from 'components/molecules/FeatureRequestViewerStatus'

const initialState = Map({
  FeatureRequestVote,
  FeatureRequestListOption,
  FeatureRequestViewerStatus,
  SearchFrInputContainer: null
})
const handler = {}

handler.createFeature = {
  options: { debounce: { wait: 500 } },
  payloadCreator: featureAPI.createFeature
}

handler.getFeatures = {
  payloadCreator: featureAPI.getFeatures
}

handler.getFeature = {
  payloadCreator: featureAPI.getFeature
}

handler.voteFeature = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: featureAPI.voteFeature
}

handler.addComment = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: featureAPI.addComment
}

handler.updateComment = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: featureAPI.updateComment
}

handler.deleteComment = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: featureAPI.deleteComment
}

handler.deleteFeature = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: featureAPI.deleteFeature
}

handler.updateFeature = {
  options: {
    debounce: { wait: 500 }
  },
  payloadCreator: featureAPI.updateFeature
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
